/* Mobicroll overrides */

.mbsc-ios.mbsc-schedule-all-day-item:after,
.mbsc-ios.mbsc-schedule-column,
.mbsc-ios.mbsc-schedule-item,
.mbsc-ios.mbsc-schedule-resource,
.mbsc-ios.mbsc-schedule-resource-group,
.mbsc-ios.mbsc-timeline-column,
.mbsc-ios.mbsc-timeline-day:after,
.mbsc-ios.mbsc-timeline-footer,
.mbsc-ios.mbsc-timeline-footer-date,
.mbsc-ios.mbsc-timeline-header,
.mbsc-ios.mbsc-timeline-header-column,
.mbsc-ios.mbsc-timeline-header-date,
.mbsc-ios.mbsc-timeline-header-month,
.mbsc-ios.mbsc-timeline-header-week,
.mbsc-ios.mbsc-timeline-resource,
.mbsc-ios.mbsc-timeline-resource-footer-cont,
.mbsc-ios.mbsc-timeline-resource-header-cont,
.mbsc-ios.mbsc-timeline-row,
.mbsc-ios.mbsc-timeline-sidebar-footer-cont,
.mbsc-ios.mbsc-timeline-sidebar-header-cont,
.mbsc-ios.mbsc-timeline-sidebar-resource,
.mbsc-ios.mbsc-timeline-slot-header,
.mbsc-ios.mbsc-timeline-slots {
  border-color: var(--slate3) !important;
}

/* .mbsc-calendar-controls.mbsc-ios {
  display: none !important;
} */

/* .mbsc-ios.mbsc-schedule-header,
.mbsc-ios.mbsc-schedule-header-item {
  border-color: var(--slate4) !important;
} */

.mbsc-ios.mbsc-schedule-header-item.mbsc-ltr {
  border-left-color: var(--slate4) !important;
}

.mbsc-ios.mbsc-schedule-all-day-item:after,
.mbsc-ios.mbsc-schedule-column,
.mbsc-ios.mbsc-schedule-item,
.mbsc-ios.mbsc-schedule-resource,
.mbsc-ios.mbsc-schedule-resource-group,
.mbsc-ios.mbsc-timeline-column,
.mbsc-ios.mbsc-timeline-day:after,
.mbsc-ios.mbsc-timeline-footer,
.mbsc-ios.mbsc-timeline-footer-date,
.mbsc-ios.mbsc-timeline-header,
.mbsc-ios.mbsc-timeline-header-column,
.mbsc-ios.mbsc-timeline-header-date,
.mbsc-ios.mbsc-timeline-header-month,
.mbsc-ios.mbsc-timeline-header-week,
.mbsc-ios.mbsc-timeline-resource,
.mbsc-ios.mbsc-timeline-resource-footer-cont,
.mbsc-ios.mbsc-timeline-resource-header-cont,
.mbsc-ios.mbsc-timeline-row,
.mbsc-ios.mbsc-timeline-sidebar-footer-cont,
.mbsc-ios.mbsc-timeline-sidebar-header-cont,
.mbsc-ios.mbsc-timeline-sidebar-resource,
.mbsc-ios.mbsc-timeline-slot-header,
.mbsc-ios.mbsc-timeline-slots {
  border-color: var(--slate3) !important;
}

.mbsc-ios.mbsc-schedule-header,
.mbsc-ios.mbsc-schedule-header-item {
  border-left: none !important;
}

.mbsc-schedule-all-day-wrapper-shadow:after {
  display: none !important;
}

.mbsc-ios.mbsc-schedule-all-day-wrapper {
  border-color: var(--slate5) !important;
}

.mbsc-ios.mbsc-schedule-header,
.mbsc-ios.mbsc-schedule-header-item {
  border-color: var(--slate5) !important;
}

.mbsc-font {
  font-size: 14px !important;
}

.mbsc-schedule-item {
  height: 3.5em !important;
}

.mbsc-schedule-time-wrapper {
  height: 3.5em !important;
}

.mbsc-ios.mbsc-calendar-cell {
  border-color: var(--slate3) !important;
}

.mbsc-ios.mbsc-calendar-day:after {
  border-color: var(--slate3) !important;
}

.mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
  background-color: var(--accentBgHover) !important;
}

.mbsc-ios.mbsc-schedule-header-day.mbsc-hover {
  background-color: var(--accentBgHover) !important;
}

.mbsc-popup {
  z-index: 1001 !important;
}
