.label {
  color: var(--grayText);
}

.inputStyle {
  border: 1px solid var(--grayBorder) !important;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  background-color: var(--inputBg) !important;
  color: var(--inputText) !important;
  height: 40px;
}

.inputStyle:focus {
  outline: none;
  border: 2px solid var(--accentBorder) !important;
}

.inputStyle::placeholder {
  color: var(--inputPlaceholderText);
}
