.Container {
  /* display: flex; */
  width: 100%;
  flex-grow: 1;
  flex-direction: row;
  min-height: 100%;
  background: var(--appBg);
}

.Content {
  margin: 0;
  transition: margin-left 100ms ease-out;
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: initial;
  gap: initial;
}

.Content.Is_Resizing {
  transition: none;
}

@media print {
  .Content {
    margin: 0 !important;
  }
}

@media (max-width: 767px) {
  .Content {
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .Content.Has_Sidebar.Sidebar_Collapsed {
    margin-left: 16px;
  }
}

.ResponsiveContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  flex-direction: row;
  min-height: 100%;
  background: var(--appBg);
}
