.Container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  flex-direction: row;
  min-height: 100%;
  background: var(--appBg);
  /* padding: var(--container-padding); */
}

.Content {
  margin: 0 auto;
  width: 100%;
}

.Content_Not_Full_Width {
  max-width: 64em;
}
