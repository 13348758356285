/* Extract colors from the following tailwind: className=" focus:ring-primary-500 flex w-full justify-center rounded-full border border-transparent bg-[#0062ff] py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2" */
.button {
  height: 32px;
  /* border-radius: 24px; */
  min-width: 32px;
}

.disabled {
  cursor: not-allowed !important;
}

.button:focus {
  outline: none;
}

.buttonIcon {
  width: 16px;
  height: 16px;
}

.radixButtonIcon {
  width: 20px;
  height: 20px;
}

.buttonEditor .radixButtonIcon {
  color: var(--grayText) !important;
}

.buttonEditor .buttonIcon {
  color: var(--grayText) !important;
}

.buttonPaddingSmall {
  padding: 0 4px;
}

.buttonPaddingMedium {
  padding: 0 8px;
}

.buttonPaddingLarge {
  padding: 0 16px;
}

/* Editor Button */

.buttonEditor {
  border-radius: 8px;
  background-color: inherit !important;
  border: none !important;
  color: var(--graySolidHover) !important;
  padding: 0 4px;
}

.buttonEditor:hover {
  background-color: var(--grayBg) !important;
  border: none !important;
  color: var(--grayTextContrast) !important;
}

.buttonEditor:hover .radixButtonIcon {
  color: var(--grayTextContrast) !important;
}

.buttonEditor:hover .buttonIcon {
  color: var(--grayTextContrast) !important;
}

.buttonEditor:active {
  border-radius: 8px;
}

.buttonEditor:focus {
  box-shadow: none !important;
}

/* Primary Button */
.buttonPrimary {
  background-color: var(--accentSolid) !important;
  color: white !important;
}

.buttonPrimary:hover {
  background-color: var(--accentSolidHover) !important;
}

.buttonPrimary:focus {
  outline: none !important;
}

/* Secondary Button */
.buttonSecondary {
  background-color: white !important;
  border: 1px solid var(--grayBorder) !important;
  color: var(--buttonTextColor) !important;
}

.buttonSecondary:hover {
  background-color: var(--grayBgHover) !important;
  border: 1px solid var(--grayBorderHover) !important;
  color: var(--buttonTextColorHover) !important;
}

.buttonSecondary:active {
  outline: none !important;
}

.buttonSecondary:focus {
  outline: none !important;
}

/* Tertiary Button */
.buttonTertiary {
  background-color: none !important;
  border: none !important;
  color: var(--buttonTextColor) !important;
}

.buttonTertiary:hover {
  background-color: none !important;
  border: none !important;
  color: var(--buttonTextColorHover) !important;
}

.buttonTertiary:active {
  background-color: none !important;
  border: none !important;
  outline: none !important;
}

.buttonTertiary:focus {
  background-color: none !important;
  border: none !important;
  outline: none !important;
}

/* Destructive Button */
.buttonDestructive {
  background-color: var(--dangerBgHover) !important;
  color: var(--dangerText) !important;
}

.buttonDestructive:hover {
  background-color: var(--dangerBgActive) !important;
  color: var(--dangerText) !important;
}

.buttonDestructive:active {
  outline: none !important;
}

.buttonDestructive:focus {
  outline: none !important;
}

/* Contstructive Button */
.buttonConstructive {
  background-color: var(--successBgHover) !important;
  color: var(--successText) !important;
}

.buttonConstructive:hover {
  background-color: var(--successBgActive) !important;
  color: var(--successText) !important;
}

.buttonConstructive:active {
  outline: none !important;
}

.buttonConstructive:focus {
  outline: none !important;
}
