.Header_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  position: sticky;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  transition: all 100ms ease-out;
  transform: translate3d(0, 0, 0);
  min-height: 64px;
  width: 100%;
}

.Header_Left {
  display: flex;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
}

.Header_Right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
}

.Header_Title {
  display: none;
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  min-width: 0;
}

.Header_Menu_Button {
  margin-right: 8px;
  pointer-events: auto;
}

@media (min-width: 768px) {
  .Header_Wrapper {
    padding: 16px;
    /* justify-content: center; */
  }

  .Header_Title {
    padding-left: 0;
    display: block !important;
  }
}

@media print {
  .Header_Wrapper {
    display: none;
  }
}
