.appBackground {
  background-color: var(--authPageBg);
}

.formColors {
  background-color: var(--authFormBg);
  border-color: var(--authFormBorder);
}

.activeTab {
  color: var(--tabActiveText);
  border-bottom-color: var(--tabActiveBorder);
  border-bottom-width: var(--tabBottomBorderWidth);
  border-style: solid;
}

.inactiveTab {
  color: var(--tabInactiveText);
  border-bottom-color: var(--tabInactiveBorder);
  border-bottom-width: var(--tabBottomBorderWidth);
  border-style: solid;
}

.continueText {
  color: var(--grayText);
  background-color: var(--authFormBg);
}

.continueBorder {
  border-color: var(--grayLine);
}

.socialMediaButton {
  background-color: var(--white);
  border-color: var(--authFormBorder);
  color: var(--grayText);
}

.socialMediaButton:hover {
  background-color: var(--grayBase);
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");

.roboto {
  font-family: "Roboto";
  font-weight: 500;
}

.segoe {
  font-family: "Segoe UI";
  font-weight: 600;
}
