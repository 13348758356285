@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  html {
    @apply h-full min-h-full bg-white;
  }
  body {
    @apply h-full min-h-full;
  }
  #root {
    @apply h-full min-h-full;
  }
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  @apply outline-none !important;
  outline: none !important;
  -webkit-appearance: none !important;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  /* border: 0 !important; */
  -webkit-appearance: none !important;
  box-shadow: none !important;
}

.mbsc-calendar-header {
  /*  display: none !important; */
}

.mbsc-schedule-date-header {
  /* display: none !important; */
}

/* button,
[type="button"],
[type="reset"],
[type="submit"] {
  background-color: inherit;
  background-image: inherit;
} */

/* .font-bold {
  font-family: "NHG Bold";
}

.font-medium {
  font-family: "NHG Medium";
} */
