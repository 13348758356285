.alertIcon {
  width: 20px;
  height: 20px;
}

.successAlertIcon {
  color: var(--successSolid) !important;
}

.warningAlertIcon {
  color: var(--warningSolid) !important;
}

.errorAlertIcon {
  color: var(--dangerSolid) !important;
}

.successAlertBg {
  background-color: var(--successBg) !important;
}

.warningAlertBg {
  background-color: var(--warningBg) !important;
}

.errorAlertBg {
  background-color: var(--dangerBg) !important;
}

.successAlertText {
  color: var(--successTextContrast) !important;
}

.warningAlertText {
  color: var(--warningTextContrast) !important;
}

.errorAlertText {
  color: var(--dangerTextContrast) !important;
}

.errorAlertListText {
  color: var(--dangerText) !important;
}
