/* Add styles for the BgBlur component */
.Mobile_Background_Blur {
  background: rgba(0, 0, 0, 0.3);
}

/* Add styles for the Sidebar_Container component */
.Sidebar_Container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background: var(--grayBgSubtle);
  transition: box-shadow 0.1s ease-in-out, transform 0.1s ease-out,
    background 0.1s ease-in-out;
  z-index: 101;
  min-width: 0;
  padding-top: 4px;
  transform: translateX(-100%);
  /* Mobile min width and max-width */
  max-width: 75%;
  min-width: 300px;
  overflow-x: hidden;
}

.Sidebar_Container.Is_Animating {
  transition: box-shadow 0.1s ease-in-out, transform 0.1s ease-out,
    background 0.1s ease-in-out, width 0.3s ease-out !important;
}

.Sidebar_Container.Show_Mobile_Sidebar {
  transform: translateX(0) !important;
}

/* Add styles for the Avatar component */
.StyledAvatar {
  margin-left: 4px;
}

/* Add this media query at the end of your Sidebar.css file */

@media screen and (min-width: 768px) {
  .Sidebar_Container {
    margin: 0;
    min-width: 0;
    transform: translateX(0);
  }

  .Sidebar_Container.Is_Hidden {
    transform: translateX(calc(-100% + 16px));
  }

  .Sidebar_Container:hover,
  .Sidebar_Container:focus-within {
    transform: none;
  }

  .Sidebar_Container.Is_Hidden:hover,
  .Sidebar_Container.Is_Hidden:focus-within {
    box-shadow: rgba(0, 0, 0, 0.2) 1.5px 0 3.5px;
  }

  .Sidebar_Container.Less_Than_Min_Width:hover,
  .Sidebar_Container.Less_Than_Min_Width:focus-within {
    box-shadow: rgba(0, 0, 0, 0.1) inset -1.5px 0 2.5px;
  }

  .Sidebar_Container:not(:hover):not(:focus-within) > div {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  .Sidebar_Container:not(.Is_Hidden):not(:hover):not(:focus-within) > div {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
  }
}

.Resize_Handler {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
  width: 4px;
  cursor: col-resize;
}

.Resize_Handler[dir="right"] {
  right: auto;
  left: -1px;
}

.Resize_Handler:hover {
  transition-delay: 0.6s;
  transition: background 0.3s ease-in-out;
  background: var(--slate5);
}

.Resize_Handler::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 12px;
}

.Toggle_Button {
  position: absolute;
  top: 17px;
  right: 16px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toggle_Button:hover {
  transition-delay: 500ms;
  transition: background 0.3s ease-in-out;
  background: var(--slate5);
}

.Sidebar_Container.Show_Mobile_Sidebar .Toggle_Button {
  display: none;
}
