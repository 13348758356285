.mobiscrollCustomMultiInput {
  width: 300px;
  border: 0px;
  font-weight: bold;
  cursor: pointer;
  padding-top: 13px;
  padding-bottom: 13px;
}

.mobiscrollCustomInput {
  border: 0px;
  width: 100px;
  padding-top: 13px;
  padding-bottom: 8px;
  font-weight: bold;
  cursor: pointer;
}

.mobiscrollCreateInput {
  border: 0px;
  font-weight: bold;
  cursor: pointer;
}

.mobiscrollCategoryInput {
  border: 0px;
  width: 150px;
  font-weight: bold;
  cursor: pointer;
}

.mobiscrollFrequencyInput {
  border: 0px;
  width: 90px;
  font-weight: bold;
  cursor: pointer;
}

.mobiscrollDatePicker {
  border: 0px;
  cursor: pointer;
}

.customDropdown {
  border: 0px;
}

.mbsc-ios.mbsc-form-control-wrapper:after,
.mbsc-ios.mbsc-form-control-wrapper:before {
  border: 0px !important;
}

.mbsc-select {
  border: 0px;
  border-radius: 0.375rem;
  border-bottom: 1px solid #cccccc;
  font-size: 15px;
}

.mbsc-textfield {
  border: 0px;
  border-radius: 0.375rem;
  border: 1px solid #cccccc;
  font-size: 15px;
}

.mbsc-icon {
  width: 1em;
  height: 1em;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: #1f1f1f !important;
}

.tox-tinymce {
  border: 1px solid #f2f2f2 !important;
}

.mbsc-ios.mbsc-select,
.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-box,
.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  background: transparent !important;
}

.mbsc-ios.mbsc-textfield-wrapper-underline {
  background: transparent !important;
}

.react-tag-input {
  border: none;
  border-bottom: 1px solid #f2f2f2;
}

.react-tag-input__input {
  font-family: "overpass";
  font-size: 15px;
}

.react-tag-input__tag {
  background: #dedede;
}

.react-tag-input__tag__content {
  font-size: 13px;
}

.mbsc-textfield.mbsc-textfield-tags.mbsc-textarea {
  max-height: 10em;
}

.mbsc-ios.mbsc-textfield-tags-inner .mbsc-ios.mbsc-select-icon.mbsc-ltr {
  margin-right: 10px;
}

.mbsc-popup-padding {
  overflow: scroll;
}

.noClearButtons .mbsc-textfield-tag-clear.mbsc-icon {
  display: none !important;
}

.noClearButtons .mbsc-textfield-tag.mbsc-ltr {
  padding-right: 0.75em;
}

.mbsc-event-list-empty {
  font-size: 15px !important;
}

.highlightTextOption {
  border: 2px solid #e6f0ff;
  border-radius: 8px;
  padding: 5px;
  margin-left: 2;
  margin-right: 2;
  background: #e6f0ff;
  color: #0062ff;
  font-family: "Overpass";
  font-size: 15px;
}

.highlightTextActive {
  border: 2px solid #0062ff;
  border-radius: 8px;
  padding: 5px;
  margin-left: 2;
  margin-right: 2;
  background: #e6f0ff;
  color: #0062ff;
  font-family: "Overpass";
  font-size: 15px;
}

.highlightTextUnselected {
  border: 2px solid #e6f0ff;
  border-radius: 8px;
  padding: 5px;
  margin-left: 2;
  margin-right: 2;
  cursor: pointer;
  background: #e6f0ff;
  color: #0062ff;
  font-family: "Overpass";
  font-size: 15px;
}

.highlightTextUnselected:hover {
  border: 2px solid #0062ff !important;
}

.highlightTextSelected {
  border: 2px solid #0062ff;
  border-radius: 8px;
  padding: 5px;
  margin-left: 2;
  margin-right: 2;
  cursor: pointer;
  background: #0062ff;
  color: #fff;
  font-family: "Overpass";
  font-size: 15px;
}

.highlightTextCorrect {
  border: 2px solid #35ac78;
  border-radius: 8px;
  padding: 5px;
  margin-left: 2;
  margin-right: 2;
  background: #d4f3e5;
  color: #35ac78;
  font-family: "Overpass";
  font-size: 15px;
}

.highlightTextWrong {
  border: 2px solid #f94144;
  border-radius: 8px;
  padding: 5px;
  margin-left: 2;
  margin-right: 2;
  background: #ffe6f3;
  color: #f94144;
  font-family: "Overpass";
  font-size: 15px;
}

.inlineChoicePlaceholder {
  border: 1px solid #0062ff;
  border-radius: 8px;
  padding: 5px;
  margin-left: 2;
  margin-right: 2;
  background: #e6f0ff;
  color: #0062ff;
  font-family: "Overpass";
  font-size: 15px;
}

.inlineTextEntry {
  border: 1px solid #0062ff;
  border-radius: 8px;
  padding: 5px;
  margin-left: 2;
  margin-right: 2;
  background: #e6f0ff;
  color: #0062ff;
  font-family: "Overpass";
  font-size: 15px;
}

div#editable {
  display: inline-block;
}

.cardQuiz {
  padding: 40px;
  background: #fff;
  max-width: 360px;
  border-radius: 20px;
  margin: 60px auto;
}

.cardShadow {
  border: 1px solid #cccccc;
}

.hotspotOption {
  border: none;
  border-radius: 8px;
  padding: 7px;
  margin-left: 2;
  margin-right: 2;
  background: #f8f8f8;
  color: #000;
  font-family: "Overpass";
  font-size: 15px;
}

.hotspotActive {
  border: 2px solid #0062ff;
  border-radius: 8px;
  padding: 7px;
  margin-left: 2;
  margin-right: 2;
  background: #f8f8f8;
  color: #0062ff;
  font-family: "Overpass";
  font-size: 15px;
}

.mbsc-ios.mbsc-popup-body {
  background: #f8f8f8 !important;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button {
  color: #0062ff !important;
}

.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header,
.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-week-days {
  background: #ffffff !important;
}

.mbsc-ios.mbsc-schedule-wrapper {
  background: #ffffff !important;
}

.fr-toolbar.fr-top {
  border-radius: 0px !important;
}

.fr-second-toolbar {
  border-radius: 0px !important;
  border: none;
}

.fr-toolbar {
  border: none !important;
}

.fr-box.fr-basic .fr-wrapper {
  border: none;
  border-bottom: 1px solid #efefef !important;
}

.fr-toolbar .fr-newline {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

*:focus {
  outline: none !important;
}

textarea:focus,
input:focus {
  outline: none !important;
}

.mbsc-textfield.mbsc-textfield-tags.mbsc-textarea {
  border: 1px solid #cccccc !important;
  border-radius: 0.375rem;
}

.mbsc-ios.mbsc-button-dark.mbsc-button-flat {
  color: #000 !important;
  font-size: 15 !important;
}

.mbsc-ios.mbsc-textfield-wrapper-underline {
  background: #fff !important;
}

.mbsc-ios.mbsc-select,
.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-box,
.mbsc-ios.mbsc-textarea-inner.mbsc-textfield-inner-outline {
  background: #fff !important;
}

.htmlParser {
  font-family: "Overpass" !important;
  user-select: text !important;
}

.highlighter {
  text-overflow: ellipsis;
}

.fr-box.fr-basic .fr-element {
  color: "#000";
}

.stickyTableContainer {
  position: relative;
  max-height: 300px;
  width: 500px;
  overflow: scroll;
}

.stickyTable {
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 100%;
}

.stickyTable > thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  min-width: 150px;
  max-width: 150px;
  background-color: #f8f8f8;
}

.stickyTable > thead {
  background-color: #f8f8f8;
  min-width: 100%;
}

.stickyTable > thead th:first-child {
  background-color: #f8f8f8;
  position: sticky;
  left: 0;
  z-index: 3;
  min-width: 150px;
  max-width: 150px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.stickyTable > tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 2;
  min-width: 150px;
  max-width: 150px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.stickyTable > tbody td {
  z-index: 1;
  min-width: 120px;
  max-width: 120px;
  padding: 10px;
}

.analyticsOverviewTable {
}

.analyticsOverviewTable > td {
  z-index: 1;
  min-width: 120px;
  max-width: 120px;
  padding: 10px;
}

.standardsTable {
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 100%;
}

.standardsTable > thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  min-width: 150px;
  max-width: 150px;
  background-color: #f8f8f8;
  border-right: 1px solid #f2f2f2;
}

.standardsTable > thead {
  background-color: #f8f8f8;
  min-width: 100%;
}

.standardsTable > thead th:first-child {
  background-color: #f8f8f8;
  position: sticky;
  left: 0;
  z-index: 3;
  min-width: 150px;
  max-width: 150px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.standardsTable > tfoot th {
  padding: 3px;
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 10px;
  min-width: 150px;
  max-width: 150px;
  background-color: #f8f8f8;
  border-right: 1px solid #f2f2f2;
}

.standardsTable > tfoot {
  background-color: #f8f8f8;
  min-width: 100%;
}

.standardsTable > tfoot th:first-child {
  background-color: #f8f8f8;
  position: sticky;
  left: 0;
  z-index: 3;
  min-width: 150px;
  max-width: 150px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.standardsTable > tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 2;
  min-width: 150px;
  max-width: 150px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.standardsTable > td {
  z-index: 1;
  min-width: 150px;
  max-width: 150px;
  padding: 15px;
  border-bottom: 1px solid #f2f2f2;
}

svg:not(:root) {
  overflow: visible !important;
}

.mbsc-schedule-all-day-wrapper {
  overflow: hidden !important;
}

.mbsc-schedule-grid-scroll {
  overflow: hidden !important;
}

.mbsc-popup-padding {
  overflow: hidden !important;
}

.gradebookTableContainer {
  position: relative;
  max-height: 300px;
  width: 500px;
  overflow: scroll;
}

.gradebookTable {
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 100%;
}

.gradebookTable > thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  min-width: 180px;
  max-width: 180px;
  background-color: #f8f8f8;
  border-right: 1px solid #f2f2f2;
}

.gradebookTable > thead {
  background-color: #f8f8f8;
  min-width: 100%;
}

.gradebookTable > thead th:first-child {
  background-color: #f8f8f8;
  position: sticky;
  left: 0;
  z-index: 3;
  min-width: 180px;
  max-width: 180px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.gradebookTable > tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 2;
  min-width: 180px;
  max-width: 180px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.gradebookTable > tbody td {
  z-index: 1;
  min-width: 180px;
  max-width: 180px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.gradebookTable > tfoot th {
  padding: 3px;
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 10px;
  min-width: 180px;
  max-width: 180px;
  background-color: #f8f8f8;
  border-right: 1px solid #f2f2f2;
}

.gradebookTable > tfoot th:first-child {
  background-color: #f8f8f8;
  position: sticky;
  left: 0;
  z-index: 3;
  min-width: 180px;
  max-width: 180px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.courseAvgTableContainer {
  position: relative;
  max-height: 300px;
  width: 500px;
  overflow: scroll;
}

.courseAvgTable {
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 100%;
}

.courseAvgTable > thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  min-width: 100px;
  max-width: 100px;
  background-color: #f8f8f8;
}

.courseAvgTable > thead {
  background-color: #f8f8f8;
  min-width: 100%;
}

.courseAvgTable > thead th:first-child {
  background-color: #f8f8f8;
  position: sticky;
  left: 0;
  z-index: 3;
  min-width: 100px;
  max-width: 100px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.courseAvgTable > tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 2;
  min-width: 100px;
  max-width: 100px;
  padding: 10px;
  border-right: 1px solid #f2f2f2;
}

.courseAvgTable > tbody td {
  z-index: 1;
  min-width: 100px;
  max-width: 100px;
  padding: 10px;
}

.mbsc-ios.mbsc-schedule-header-day.mbsc-selected {
  background: #0062ff !important;
}

.mbsc-ios.mbsc-schedule-time-indicator {
  color: #0062ff !important;
}

.mbsc-ios.mbsc-schedule-cursor-time,
.mbsc-ios.mbsc-schedule-time-indicator-time {
  color: #0062ff !important;
}

.mbsc-ios.mbsc-schedule-time-indicator {
  border-color: #0062ff !important;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  background: #0062ff;
  border: #0062ff;
}

.mbsc-schedule-time-indicator-x.mbsc-ltr {
  color: #0062ff;
}

.mbsc-ios.mbsc-segmented {
  background: #f0f0f0;
}
