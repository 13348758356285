@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/blue.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/red.css";
@import "@radix-ui/colors/yellow.css";
@import "@radix-ui/colors/whiteA.css";
@import "@radix-ui/colors/blackA.css";
@import "@radix-ui/colors/slateA.css";

:root {
  --accentBase: #0062ff; /* Main color */
  --accentBgSubtle: #f0f7ff; /* Lightest shade */
  --accentBg: #0062ff; /* Lighter shade */
  --accentBgHover: #c7ddff; /* Light shade */
  --accentBgActive: #a8cbff; /* Noticeably blue, but still light */
  --accentLine: #0062ff; /* Medium-light blue */
  --accentBorder: #0062ff; /* A lighter version of the main color */
  --accentBorderHover: #0062ff; /* A slightly darker shade of the main color */
  --accentSolid: #0062ff; /* Darker shade */
  --accentSolidHover: #0062ff; /* A deep blue, much darker than the main color */
  --accentText: #0062ff; /* The darkest shade, providing strong contrast */
  --accentTextContrast: #0062ff; /* Main color for contrast text */

  --successBase: var(--green1);
  --successBgSubtle: var(--green2);
  --successBg: var(--green3);
  --successBgHover: var(--green4);
  --successBgActive: var(--green5);
  --successLine: var(--green6);
  --successBorder: var(--green7);
  --successBorderHover: var(--green8);
  --successSolid: var(--green9);
  --successSolidHover: var(--green10);
  --successText: var(--green11);
  --successTextContrast: var(--green12);

  --warningBase: var(--yellow1);
  --warningBgSubtle: var(--yellow2);
  --warningBg: var(--yellow3);
  --warningBgHover: var(--yellow4);
  --warningBgActive: var(--yellow5);
  --warningLine: var(--yellow6);
  --warningBorder: var(--yellow7);
  --warningBorderHover: var(--yellow8);
  --warningSolid: var(--yellow9);
  --warningSolidHover: var(--yellow10);
  --warningText: var(--yellow11);
  --warningTextContrast: var(--yellow12);

  --dangerBase: var(--red1);
  --dangerBgSubtle: var(--red2);
  --dangerBg: var(--red3);
  --dangerBgHover: var(--red4);
  --dangerBgActive: var(--red5);
  --dangerLine: var(--red6);
  --dangerBorder: var(--red7);
  --dangerBorderHover: var(--red8);
  --dangerSolid: var(--red9);
  --dangerSolidHover: var(--red10);
  --dangerText: var(--red11);
  --dangerTextContrast: var(--red12);

  --grayBase: var(--slate1);
  --grayBgSubtle: var(--slate2);
  --grayBg: var(--slate3);
  --grayBgHover: var(--slate4);
  --grayBgActive: var(--slate5);
  --grayLine: var(--slate6);
  --grayBorder: var(--slate7);
  --grayBorderHover: var(--slate8);
  --grayFocusRing: var(--slate8);
  --graySolid: var(--slate9);
  --grayPlaceholderText: var(--slate9);
  --graySolidHover: var(--slate10);
  --grayText: var(--slate11);
  --grayTextContrast: var(--slate12);

  --panel: white;
  --panelContrast: var(--blackA11);
  --shadow: var(--slateA3);
  --overlay: var(--blackA8);

  --authPageBg: var(--grayBgSubtle);
  --authFormBg: var(--whiteA12);
  --authFormBorder: var(--grayBorder);

  --tabInactiveBorder: var(--grayLine);
  --tabInactiveText: var(--grayPlaceholderText);
  --tabActiveBorder: var(--accentBorder);
  --tabActiveText: var(--accentText);

  --inputBg: var(--whiteA12);
  --inputText: var(--grayTextContrast);
  --inputPlaceholderText: var(--grayPlaceholderText);

  --buttonTextColor: var(--grayText);
  --buttonTextColorHover: var(--grayText);

  /* Planner */
  --editorAttributionHeaderColor: var(--grayText);
  --editorAttributionHeaderActiveColor: var(--grayTextContrast);
  --editorAttributionHeaderCaret: var(--accentSolid);
  --editorHeaderGradient: linear-gradient(
    rgba(238, 238, 238, 0.5) 0%,
    rgba(238, 238, 238, 0.447) 10.67%,
    rgba(238, 238, 238, 0.396) 19.64%,
    rgba(238, 238, 238, 0.353) 27.12%,
    rgba(238, 238, 238, 0.31) 33.33%,
    rgba(238, 238, 238, 0.275) 38.51%,
    rgba(238, 238, 238, 0.243) 42.88%,
    rgba(238, 238, 238, 0.21) 46.65%,
    rgba(238, 238, 238, 0.184) 50.06%,
    rgba(238, 238, 238, 0.157) 53.33%,
    rgba(238, 238, 238, 0.133) 56.68%,
    rgba(238, 238, 238, 0.106) 60.33%,
    rgba(238, 238, 238, 0.082) 64.52%,
    rgba(238, 238, 238, 0.055) 69.46%,
    rgba(238, 238, 238, 0.027) 75.38%,
    rgba(238, 238, 238, 0) 82.5%
  );

  --appBg: white;
  --plannerBg: white;
  --editorTitleColor: var(--blackA12);
  --editorTitleBorderColor: var(--grayLine);

  /* Editor liveblocks */

  --color-surface: 255, 255, 255;
  --color-surface-over: 255, 255, 255;
  --color-foreground: 60, 59, 63;
  --color-primary: 81, 22, 245;
  --opacity-surface-selection: 0.12;
  --container-max-width: 1000px;
  --shadow-over: 0 0 0 0.5px rgba(6, 0, 19, 0.09),
    0 4px 12px -2px rgba(6, 0, 19, 0.12), 0 24px 40px -12px rgba(6, 0, 19, 0.28);
  --editorSelectionHighlight: var(--accentBgHover);

  --editorTitleBackground: var(--whiteA10);
  --editorTitleBackgroundActive: white;
  --editorHoverBackground: rgba(0, 0, 0, 0.04);
  --insertBlockPopoverShadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.04) 0px 4px 12px;
  --insertBlockPopoverBg: white;
  --insertBlockTileBg: var(--grayBg);
  --insertBlockTileBgHover: var(--grayBgSubtle);

  /* Add Course Icon background */

  --addCourseCardBg: var(--grayBase);
  --addCourseIconBgHover: var(--blackA4);
  --addCourseCardBorder: var(--grayBorder);

  --theme-backgroundTransition: "background 100ms ease-in-out";

  --tooltipBackground: var(--blackA12);
}
