@import "@radix-ui/colors/slateDark.css";
@import "@radix-ui/colors/blueDark.css";
@import "@radix-ui/colors/greenDark.css";
@import "@radix-ui/colors/redDark.css";
@import "@radix-ui/colors/yellowDark.css";
@import "@radix-ui/colors/whiteA.css";
@import "@radix-ui/colors/blackA.css";
@import "@radix-ui/colors/indigo.css";

.dark-theme {
  color-scheme: dark;

  --accentBase: var(--blue1);
  --accentBgSubtle: var(--blue2);
  --accentBg: var(--blue3);
  --accentBgHover: var(--blue4);
  --accentBgActive: var(--blue5);
  --accentLine: var(--blue6);
  --accentBorder: var(--blue7);
  --accentBorderHover: var(--blue8);
  --accentSolid: var(--blue9);
  --accentSolidHover: var(--blue10);
  --accentText: var(--blue11);
  --accentTextContrast: var(--blue12);

  --successBase: var(--green1);
  --successBgSubtle: var(--green2);
  --successBg: var(--green3);
  --successBgHover: var(--green4);
  --successBgActive: var(--green5);
  --successLine: var(--green6);
  --successBorder: var(--green7);
  --successBorderHover: var(--green8);
  --successSolid: var(--green9);
  --successSolidHover: var(--green10);
  --successText: var(--green11);
  --successTextContrast: var(--green12);

  --warningBase: var(--yellow1);
  --warningBgSubtle: var(--yellow2);
  --warningBg: var(--yellow3);
  --warningBgHover: var(--yellow4);
  --warningBgActive: var(--yellow5);
  --warningLine: var(--yellow6);
  --warningBorder: var(--yellow7);
  --warningBorderHover: var(--yellow8);
  --warningSolid: var(--yellow9);
  --warningSolidHover: var(--yellow10);
  --warningText: var(--yellow11);
  --warningTextContrast: var(--yellow12);

  --dangerBase: var(--red1);
  --dangerBgSubtle: var(--red2);
  --dangerBg: var(--red3);
  --dangerBgHover: var(--red4);
  --dangerBgActive: var(--red5);
  --dangerLine: var(--red6);
  --dangerBorder: var(--red7);
  --dangerBorderHover: var(--red8);
  --dangerSolid: var(--red9);
  --dangerSolidHover: var(--red10);
  --dangerText: var(--red11);
  --dangerTextContrast: var(--red12);

  --grayBase: var(--slate1);
  --grayBgSubtle: var(--slate2);
  --grayBg: var(--slate3);
  --grayBgHover: var(--slate4);
  --grayBgActive: var(--slate5);
  --grayLine: var(--slate6);
  --grayBorder: var(--slate7);
  --grayBorderHover: var(--slate8);
  --grayFocusRing: var(--slate8);
  --graySolid: var(--slate9);
  --grayPlaceholderText: var(--slate9);
  --graySolidHover: var(--slate10);
  --grayText: var(--slate11);
  --grayTextContrast: var(--slate12);

  --panel: var(--slate2);
  --panelContrast: var(--whiteA11);
  --shadow: black;
  --overlay: var(--blackA12);

  /* --authPageBg: var(--grayBase); */
  --authPageBg: rgb(5 5 10);
  --appBg: rgb(5 5 10);
  /* --authFormBg: var(--grayBgSubtle); */
  --authFormBg: var(--slate1);
  --authFormBorder: var(--whiteA4);

  --tabInactiveBorder: var(--grayBorder);
  --tabInactiveText: var(--grayText);
  --tabActiveBorder: var(--whiteA11);
  --tabActiveText: var(--grayTextContrast);

  --inputBg: var(--grayBgSubtle);
  --inputText: white;
  --inputPlaceholderText: var(--whiteA7);

  --buttonTextColor: var(--grayTextContrast);
  --buttonTextColorHover: var(--grayText);

  /* Planner */
  --editorAttributionHeaderColor: var(--grayText);
  --editorAttributionHeaderActiveColor: var(--grayTextContrast);
  --editorAttributionHeaderCaret: var(--accentSolid);
  --editorHeaderGradient: linear-gradient(
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.447) 10.67%,
    rgba(0, 0, 0, 0.396) 19.64%,
    rgba(0, 0, 0, 0.353) 27.12%,
    rgba(0, 0, 0, 0.31) 33.33%,
    rgba(0, 0, 0, 0.275) 38.51%,
    rgba(0, 0, 0, 0.243) 42.88%,
    rgba(0, 0, 0, 0.21) 46.65%,
    rgba(0, 0, 0, 0.184) 50.06%,
    rgba(0, 0, 0, 0.157) 53.33%,
    rgba(0, 0, 0, 0.133) 56.68%,
    rgba(0, 0, 0, 0.106) 60.33%,
    rgba(0, 0, 0, 0.082) 64.52%,
    rgba(0, 0, 0, 0.055) 69.46%,
    rgba(0, 0, 0, 0.027) 75.38%,
    rgba(0, 0, 0, 0) 82.5%
  );

  --plannerBg: rgb(5 5 10);
  --editorTitleColor: var(--whiteA12);
  --editorTitleBorderColor: var(--grayLine);
  --editorTitleBackground: var(--blackA10);
  --editorTitleBackgroundActive: black;
  --editorHoverBackground: rgba(255, 255, 255, 0.04);
  --insertBlockPopoverBg: var(--grayBgHover);
  --insertBlockTileBg: var(--grayBg);
  --insertBlockTileBgHover: var(--grayBgSubtle);

  --color-surface: 31, 30, 32;
  --color-surface-over: 51, 50, 52;
  --color-foreground: 230, 228, 234;
  --color-primary: 100, 27, 255;
  --opacity-surface-selection: 0.24;

  --shadow-over: 0 0 0 0.5px rgba(0, 0, 0, 0.17),
    0 4px 12px -2px rgba(0, 0, 0, 0.2), 0 24px 40px -12px rgba(0, 0, 0, 0.36);
  --editorSelectionHighlight: var(--accentBgSubtle);
}
