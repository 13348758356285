/* Extract colors from the following tailwind: className=" focus:ring-primary-500 flex w-full justify-center rounded-full border border-transparent bg-[#0062ff] py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2" */
.buttonLarge {
  height: 40px;
}

.buttonLarge:focus {
  outline: none;
}

/* Primary Button */

.disabled {
  cursor: not-allowed !important;
}

.buttonPrimary {
  background-color: var(--accentSolid) !important;
  color: white !important;
  padding: 0 16px;
}

.buttonPrimary:hover {
  background-color: var(--accentSolidHover) !important;
}

.buttonPrimary:active {
  background-color: var(--accentSolidHover) !important;
}

.buttonPrimary:focus {
  background-color: var(--accentSolidHover) !important;
  /* box-shadow: 0 0 0 3px var(--accentSolidHover) !important; */
}

/* Secondary Button */

.buttonSecondary {
  background-color: inherit !important;
  border: 1px solid var(--grayBorder) !important;
  color: var(--buttonTextColor) !important;
  padding: 0 16px;
}

.buttonSecondary:hover {
  background-color: var(--grayBg) !important;
  border: 1px solid var(--grayBorderHover) !important;
  color: var(--buttonTextColorHover) !important;
}

.buttonSecondary:active {
  background-color: var(--grayBg) !important;
  color: var(--buttonTextColorHover) !important;
}

.buttonSecondary:focus {
  background-color: var(--grayBg) !important;
  /* box-shadow: 0 0 0 3px var(--grayBorderHover) !important; */
  color: var(--buttonTextColorHover) !important;
}

/* Tertiary Button */

.buttonTertiary {
  background-color: none !important;
  border: none !important;
  color: var(--buttonTextColor) !important;
}

.buttonTertiary:hover {
  background-color: none !important;
  border: none !important;
  color: var(--buttonTextColorHover) !important;
}

.buttonTertiary:active {
  background-color: none !important;
  border: none !important;
  color: var(--buttonTextColorHover) !important;
}

.buttonTertiary:focus {
  background-color: none !important;
  border: none !important;
  color: var(--buttonTextColorHover) !important;
}

/* Destructive Button */

.buttonDestructive {
  background-color: var(--dangerBgHover) !important;
  color: var(--dangerTextContrast) !important;
  padding: 0 16px;
}

.buttonDestructive:hover {
  background-color: var(--dangerBgActive) !important;
  color: var(--dangerTextContrast) !important;
}

.buttonDestructive:active {
  background-color: var(--dangerBgActive) !important;
  color: var(--dangerTextContrast) !important;
}

.buttonDestructive:focus {
  background-color: var(--dangerBgActive) !important;
  /* box-shadow: 0 0 0 3px var(--dangerBgActive) !important; */
  color: var(--dangerTextContrast) !important;
}

/* Contstructive Button */

.buttonConstructive {
  background-color: var(--successBgHover) !important;
  color: var(--successTextContrast) !important;
  padding: 0 16px;
}

.buttonConstructive:hover {
  background-color: var(--successBgActive) !important;
  color: var(--successTextContrast) !important;
}

.buttonConstructive:active {
  background-color: var(--successBgActive) !important;
  color: var(--successTextContrast) !important;
}

.buttonConstructive:focus {
  background-color: var(--successBgActive) !important;
  /* box-shadow: 0 0 0 3px var(--successBgActive) !important; */
  color: var(--successTextContrast) !important;
}

.buttonIconLg {
  width: 18px;
  height: 18px;
}

.buttonEditor {
  border-radius: 12px;
  background-color: inherit !important;
  border: none !important;
  color: var(--buttonTextColor) !important;
  padding: 0 8px;
}

.buttonEditor:hover {
  background-color: var(--grayBg) !important;
  border: none !important;
}

.buttonEditor:active {
}

.buttonEditor:focus {
  box-shadow: none !important;
}

.editorPlusButton:focus {
  background-color: var(--grayBg) !important;
}
