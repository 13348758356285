/* Slate.js Main Editor styling */

.slate {
  color: rgb(var(--color-foreground));
  line-height: 1.75em;
  white-space: pre-wrap;
}

.slate *::selection {
  color: rgba(var(--color-foreground), 1);
  background: var(--editorSelectionHighlight);
}

.slate h1,
.slate .h1,
.slate h2,
.slate .h2,
.slate h3,
.slate .h3 {
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: -0.0125em;
}

.slate h1,
.slate .h1 {
  padding-top: 0.5em;
  margin-bottom: 0.25em;
  font-size: 1.875em;
}

.slate h1 ~ .inline_actions,
.slate h1 ~ .toolbar,
.slate h1 ~ .avatars {
  padding-top: 2.95em;
}

.slate h2,
.slate .h2 {
  padding-top: 0.5em;
  margin-bottom: 0.25em;
  font-size: 1.5em;
}

.slate h2 ~ .inline_actions,
.slate h2 ~ .toolbar,
.slate h2 ~ .avatars {
  padding-top: 2.15em;
}

.slate h3,
.slate .h3 {
  padding-top: 0.5em;
  margin-bottom: 0.25em;
  font-size: 1.25em;
}

.slate h3 ~ .inline_actions,
.slate h3 ~ .toolbar,
.slate h3 ~ .avatars {
  padding-top: 1.15em;
}

.slate p .caret,
.slate .p .caret {
  padding-top: 0.15em;
}

.slate .embed {
  padding: 0.5em 0;
}

.slate .embed ~ .inline_actions,
.slate .embed ~ .toolbar,
.slate .embed ~ .avatars {
  padding-top: 0.5em;
}

/* Hack for caret not visible after bulleted list */
.slate span[data-slate-node="text"] {
  /* Add your custom styles here */
  min-width: 5px;
}

/* Slate Board View styling */

.slate_board {
  color: rgb(var(--color-foreground));
  line-height: 1.75em;
  white-space: pre-wrap;
}

.slate_board *::selection {
  color: rgba(var(--color-foreground), 1);
  background: var(--editorSelectionHighlight);
}

.slate_board h1,
.slate_board .h1,
.slate_board h2,
.slate_board .h2,
.slate_board h3,
.slate_board .h3 {
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: -0.0125em;
}

.slate_board h1,
.slate_board .h1 {
  padding-top: 1.4em;
  margin-bottom: 0.25em;
  font-size: 1.4em;
}

.slate_board h2,
.slate_board .h2 {
  padding-top: 1.4em;
  margin-bottom: 0.25em;
  font-size: 1.2em;
}

.slate_board h3,
.slate_board .h3 {
  padding-top: 1em;
  margin-bottom: 0.25em;
  font-size: 1.05em;
}

.slate_board p,
.slate_board li,
.slate_board .p .element {
  padding-top: 0.5em;
  margin-bottom: 0.25em;
  font-size: 0.9em;
}

.slate_board p .caret,
.slate_board .p .caret {
  padding-top: 0.15em;
}

.slate_board .embed {
  padding: 0.5em 0;
}

/* Hack for caret not visible after bulleted list */
.slate_board span[data-slate-node="text"] {
  /* Add your custom styles here */
  min-width: 5px;
}

/* Presenter Mode */

/* Slate.js Main Editor styling */

.slate_presenter {
  color: rgb(var(--color-foreground));
  line-height: 1.75em;
  white-space: pre-wrap;
}

.slate_presenter *::selection {
  color: rgba(var(--color-foreground), 1);
  background: var(--editorSelectionHighlight);
}

.slate_presenter h1,
.slate_presenter .h1,
.slate_presenter h2,
.slate_presenter .h2,
.slate_presenter h3,
.slate_presenter .h3 {
  font-weight: 600;
  line-height: 1.7em;
  letter-spacing: -0.0125em;
}

.slate_presenter h1,
.slate_presenter .h1 {
  padding-top: 1.7em;
  margin-bottom: 0.5em;
  font-size: 2.1em;
}

.slate_presenter h2,
.slate_presenter .h2 {
  padding-top: 1.5em;
  margin-bottom: 0.4em;
  font-size: 1.9em;
}

.slate_presenter h3,
.slate_presenter .h3 {
  padding-top: 1.3em;
  margin-bottom: 0.3em;
  font-size: 1.7em;
}

.slate_presenter p,
.slate_presenter li,
.slate_presenter .p,
.slate_presenter .li {
  padding-top: 0.75em;
  margin-bottom: 0.25em;
  font-size: 1.3em;
  line-height: 1.3em;
}

.slate_presenter .embed {
  padding: 0.5em 0;
}

.slate_presenter .embed ~ .inline_actions,
.slate_presenter .embed ~ .toolbar,
.slate_presenter .embed ~ .avatars {
  padding-top: 0.5em;
}

/* Hack for caret not visible after bulleted list */
.slate_presenter span[data-slate-node="text"] {
  /* Add your custom styles here */
  min-width: 5px;
}

.katex {
  font: normal 1.5em KaTeX_Main, Times New Roman, serif !important;
}
